<template>
  <div>
    <div style="" class="game-card">
      <div class="game-type">
        <div class="country">
          {{ fixture.country }} • {{ shortenName(fixture.tournament, 40) }}
        </div>

        <div class="date">
          <div>
            <span v-show="!live">{{ formatMatchDate(fixture.date) }}</span>
          </div>

          <div v-show="live" class="">
            {{ fixture.match_status }}
            <span> {{ fixture.event_time }} </span>
          </div>
        </div>
      </div>

      <div class="games">
        <div class="px-0 text-dark game-card-teams">
          <router-link
            :to="{
              name: 'match',
              params: {
                game_id: fixture.game_id === undefined ? 0 : fixture.game_id,
                name: toURL(fixture.name),
                status: live ? 'live' : 'prematch',
              },
            }"
            class="match d-block font-weight-bold"
            style="text-decoration: none"
          >
            <div>
              <span
                v-show="live"
                class="score"
                v-text="fixture.home_score"
              ></span>
              {{ shortenName(home_team, 20) }}
            </div>
            <div class="mb-0">
              <span
                v-show="live"
                class="score"
                v-text="fixture.away_score"
              ></span>
              {{ shortenName(away_team, 20) }}
            </div>
          </router-link>
        </div>

        <div class="odds">
          <Odd
            v-for="(o, index) in fixture.highlight_market.outcomes"
            v-bind:key="getKey(o.odd_id)"
            v-bind:id="o.odd_id"
            v-bind:index="index"
            class="col text-center bold"
            v-bind:class="getClasses(o)"
            style="
              margin: 0px !important;
              padding: 2px !important;
              height: fit-content;
            "
            v-bind:sport_id="fixture.sport_id"
            v-bind:boostedGame="fixture.boosted === 1"
            v-bind:match_id="fixture.match_id"
            v-bind:market_name="fixture?.highlight_market?.market_name"
            v-bind:home_team="home_team"
            v-bind:away_team="away_team"
            v-bind:market_id="fixture.highlight_market.market_id"
            v-bind:outcome_id="o.outcome_id"
            v-bind:specifier="fixture.highlight_market.specifier"
            v-bind:active="o.active"
            v-bind:status="fixture.highlight_market.status"
            v-bind:odds="o.odds"
            v-bind:producer_id="fixture.producer_id"
            v-bind:producer_status="fixture.producer_status"
            v-bind:previous_odds="o.previous_odds"
            v-bind:alias="o.alias"
            v-bind:probability="o.probability"
            v-bind:pick="o"
          />
        </div>
      </div>

      <div class="markets">
        <div>
          <p v-if="fixture.boosted === 1" class="boosted">
            🔥 {{ $t("boosted") }}
          </p>
        </div>
        <p>
          <router-link
            :to="{
              name: 'match',
              params: {
                game_id: fixture.game_id === undefined ? 0 : fixture.game_id,
                name: toURL(fixture.name),
                status: live ? 'live' : 'prematch',
              },
            }"
            style="text-decoration: none; color: var(--text-color)"
          >
            +{{ fixture.active_markets }} {{ $t("markets") }}</router-link
          >
        </p>
      </div>
    </div>

    <div class="space-1"></div>
  </div>
</template>

<style scoped>
p {
  margin: 0;
  padding: 0;
}
.games {
  display: flex;
  align-items: center;
  margin: 8px 0 0 0;
  padding: 0;
  gap: 8px;
}
.game-type {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  justify-content: space-between;
}

.country {
  width: 50%;
}
.date {
  width: 50%;
  display: flex;
  text-align: right;
  gap: 8px;
  justify-content: end;
  flex-wrap: wrap;
}
.date span {
  /* color: #545151; */
  color: var(--sub-text-color);
}

.game-type > div,
.game-type > div span {
  padding: 0;
  margin: 0;
  color: var(--sub-text-color);
}
.game-card-teams {
  width: 40%;
}
.odds {
  width: 60%;
}
.match > div {
  line-height: 12px;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
  /* color: #0e0d0d; */
  color: var(--text-color);
}
.odds {
  display: flex;
  gap: 0.2rem;
  flex-grow: 1;
}

.odds > div {
  flex: 1;
  max-width: none;
}
.game-card {
  flex-direction: column;
  gap: 8px;
  padding: 8px 20px;
  border-bottom: solid var(--fixture-border) 1px;
}
.markets {
  text-align: right;
  justify-content: end;
  /* color: #1c1b1b;
  color: red; */
  color: var(--text-color);
  font-weight: 700;
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
}
.boosted {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 310px;
  /* background: #f5f5f5;
  color: #140202; */
  background-color: var(--background-color);
  color: var(--text-color);

  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.1px;
}
</style>

<script>
import Odd from "./Odd.vue";

export default {
  components: {
    Odd,
  },
  name: "fixture",
  props: {
    fixture: {
      required: true,
    },
    live: {
      required: false,
      default: false,
    },
    tab: {
      required: false,
      default: "highlights",
    },
  },
  computed: {
    event_time: function () {
      var minutes = parseInt(this.seconds / 60);
      //var seconds = this.seconds % 60;

      var min = minutes;

      if (minutes < 10) {
        min = "0" + min;
      }

      return min + "'";
    },
    sport_name: function () {
      return this.getSportName(this.fixture.sport_id);
    },
    country_name: function () {
      return this.fixture.country;
    },
    home_team: function () {
      return this.getHomeCompetitorName(this.fixture.name);
    },
    away_team: function () {
      return this.getAwayCompetitorName(this.fixture.name);
    },
    sportIcon: function () {
      return this.getSportIcon(this.fixture.sport_id);
    },
    sportAlt: function () {
      switch (this.fixture.sport_id) {
        case 1:
          return "Soccer Icon";

        case 2:
          return "basketball icon";

        case 4:
          return "hockey icon";

        case 5:
          return "tennis icon";

        case 22:
          return "/assets/icons/dart.svg";

        case 20:
          return "/assets/icons/tabletennis.svg";

        case 6:
          return "/assets/icons/handball.svg";

        case 12:
          return "/assets/icons/rugby.svg";

        case 10:
          return "/assets/icons/boxing.svg";

        case 31:
          return "/assets/icons/badminton.svg";

        case 3:
          return "/assets/icons/baseball.svg";
      }
      return "";
    },
  },
  methods: {
    isVisible: function () {
      return (
        this.fixture !== undefined &&
        this.fixture !== null &&
        this.fixture.highlight_market !== undefined &&
        this.fixture.highlight_market !== null
      );
    },

    isLocked: function (o) {
      if (parseInt(o.producer_id) === 1) {
        return (
          parseInt(o.status) > 0 ||
          parseInt(o.active) === 0 ||
          parseInt(o.producer_status) === 0
        );
      } else {
        return parseInt(o.status) > 0 || parseInt(o.active) === 0;
      }
    },

    getKey: function (index) {
      var prefix = "odds-" + index;
      return Math.random()
        .toString(10)
        .replace("0.", "odd-id-" + prefix + "-");
    },

    getV: function (x) {
      return x;
    },
    getHomeCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }

      return matchName.split("vs.")[0];
    },
    getAwayCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }
      return matchName.split("vs.")[1];
    },
    // getClasses: function (o) {

    //   if (this.fixture === undefined || this.fixture.highlight_market === undefined) {

    //     return ""

    //   }

    //   //var st = parseInt(o.active) === 0 || parseInt(o.status) !== 0 ? 'btn-odd' : 'btn-odd';

    //   return "btn-odd small-txt mr-1 mt-1" +
    //     " match-" + this.fixture.match_id +
    //     " producer-" + o.producer_id +
    //     " match-" + this.fixture.match_id +
    //     " market-" + this.fixture.highlight_market.market_id +
    //     " outcome-" + o.outcome_id +
    //     " specifier-" + this.fixture.highlight_market.specifier;
    // },

    getClasses: function (o) {
      var col = "col-2";
      // if (this.fixture === undefined || this.fixture.highlight_market === undefined) {
      //   return ""
      // }
      if (this.fixture.length == 2) {
        col = "col-3";
      }

      return (
        "" +
        " match-" +
        this.fixture.match_id +
        " producer-" +
        o.producer_id +
        " match-" +
        this.fixture.match_id +
        " market-" +
        o.market_id +
        " outcome-" +
        o.outcome_id +
        " specifier-" +
        o.specifier +
        " " +
        col
      );
    },

    matchURL: function () {
      var game_id = this.fixture.game_id;
      var name = this.toURL(this.fixture.name);

      var url = "/match/" + game_id + "/" + name;
      return url;
    },
    toURL: function (name) {
      if (name === undefined) {
        return "-";
      }

      return name.replace(/[^a-z0-9+]+/gi, "-");
    },
    getOddDirectionClass: function (outcome) {
      if (parseInt(outcome.active) === 0 || parseInt(outcome.status) != 0) {
        return "";
      }

      if (parseFloat(outcome.odd) >= parseFloat(outcome.previous_odds)) {
        return "arrow green up";
      }

      return "arrow red down";
    },
  },
  mounted: function () {
    if (this.fixture.event_time.length > 0) {
      var p = this.fixture.event_time.split(":");
      var minutes = p[0];
      var seconds = p[1];
      seconds = parseInt(seconds);
      seconds = seconds + parseInt(minutes) * 60;
      this.seconds = seconds;
    }

    this.isLive = parseInt(this.producer_id) !== 3;

    if (this.fixture.match_status === "NotStarted") {
      this.upcoming = true;
      this.isLive = false;
      this.to_start = this.timeToStart(this.fixture.date);
    }
  },
  data: function () {
    return {
      color: "",
      seconds: 0,
      to_start: "",
      formattedFixture: "",
      upcoming: "",
      isLive: "",
      odds: "",
      status: "",
      active: "",
    };
  },
};
</script>
