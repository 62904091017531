var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"game-card"},[_c('div',{staticClass:"game-type"},[_c('div',{staticClass:"country"},[_vm._v(" "+_vm._s(_vm.fixture.country)+" • "+_vm._s(_vm.shortenName(_vm.fixture.tournament, 40))+" ")]),_c('div',{staticClass:"date"},[_c('div',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.live),expression:"!live"}]},[_vm._v(_vm._s(_vm.formatMatchDate(_vm.fixture.date)))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.live),expression:"live"}]},[_vm._v(" "+_vm._s(_vm.fixture.match_status)+" "),_c('span',[_vm._v(" "+_vm._s(_vm.fixture.event_time)+" ")])])])]),_c('div',{staticClass:"games"},[_c('div',{staticClass:"px-0 text-dark game-card-teams"},[_c('router-link',{staticClass:"match d-block font-weight-bold",staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: 'match',
            params: {
              game_id: _vm.fixture.game_id === undefined ? 0 : _vm.fixture.game_id,
              name: _vm.toURL(_vm.fixture.name),
              status: _vm.live ? 'live' : 'prematch',
            },
          }}},[_c('div',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.live),expression:"live"}],staticClass:"score",domProps:{"textContent":_vm._s(_vm.fixture.home_score)}}),_vm._v(" "+_vm._s(_vm.shortenName(_vm.home_team, 20))+" ")]),_c('div',{staticClass:"mb-0"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.live),expression:"live"}],staticClass:"score",domProps:{"textContent":_vm._s(_vm.fixture.away_score)}}),_vm._v(" "+_vm._s(_vm.shortenName(_vm.away_team, 20))+" ")])])],1),_c('div',{staticClass:"odds"},_vm._l((_vm.fixture.highlight_market.outcomes),function(o,index){return _c('Odd',{key:_vm.getKey(o.odd_id),staticClass:"col text-center bold",class:_vm.getClasses(o),staticStyle:{"margin":"0px !important","padding":"2px !important","height":"fit-content"},attrs:{"id":o.odd_id,"index":index,"sport_id":_vm.fixture.sport_id,"boostedGame":_vm.fixture.boosted === 1,"match_id":_vm.fixture.match_id,"market_name":_vm.fixture?.highlight_market?.market_name,"home_team":_vm.home_team,"away_team":_vm.away_team,"market_id":_vm.fixture.highlight_market.market_id,"outcome_id":o.outcome_id,"specifier":_vm.fixture.highlight_market.specifier,"active":o.active,"status":_vm.fixture.highlight_market.status,"odds":o.odds,"producer_id":_vm.fixture.producer_id,"producer_status":_vm.fixture.producer_status,"previous_odds":o.previous_odds,"alias":o.alias,"probability":o.probability,"pick":o}})}),1)]),_c('div',{staticClass:"markets"},[_c('div',[(_vm.fixture.boosted === 1)?_c('p',{staticClass:"boosted"},[_vm._v(" 🔥 "+_vm._s(_vm.$t("boosted"))+" ")]):_vm._e()]),_c('p',[_c('router-link',{staticStyle:{"text-decoration":"none","color":"var(--text-color)"},attrs:{"to":{
            name: 'match',
            params: {
              game_id: _vm.fixture.game_id === undefined ? 0 : _vm.fixture.game_id,
              name: _vm.toURL(_vm.fixture.name),
              status: _vm.live ? 'live' : 'prematch',
            },
          }}},[_vm._v(" +"+_vm._s(_vm.fixture.active_markets)+" "+_vm._s(_vm.$t("markets")))])],1)])]),_c('div',{staticClass:"space-1"})])
}
var staticRenderFns = []

export { render, staticRenderFns }